@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@-webkit-keyframes home_rotate__HXvlE {
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@keyframes home_rotate__HXvlE {
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@-webkit-keyframes home_clear__2wYTH {
  100% {
    opacity: 0%;
  }
}

@keyframes home_clear__2wYTH {
  100% {
    opacity: 0%;
  }
}

@-webkit-keyframes home_sinus__1C9np {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }

  25% {
    -webkit-transform: translate(200px, -25px);
            transform: translate(200px, -25px);
  }
  50% {
    -webkit-transform: translate(400px, 25px);
            transform: translate(400px, 25px);
  }
  75% {
    -webkit-transform: translate(600px, -25px);
            transform: translate(600px, -25px);
  }
  100% {
    -webkit-transform: translate(800px, 25px);
            transform: translate(800px, 25px);
  }
}

@keyframes home_sinus__1C9np {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }

  25% {
    -webkit-transform: translate(200px, -25px);
            transform: translate(200px, -25px);
  }
  50% {
    -webkit-transform: translate(400px, 25px);
            transform: translate(400px, 25px);
  }
  75% {
    -webkit-transform: translate(600px, -25px);
            transform: translate(600px, -25px);
  }
  100% {
    -webkit-transform: translate(800px, 25px);
            transform: translate(800px, 25px);
  }
}

.home_home__z3mBK {
  position: relative;
  max-width: 100vw;
  height: 1500px;
  background: "#313036";
  overflow: hidden;
}

.home_home__z3mBK h1 {
  text-align: center;
}

.home_logoHome1__2a5-J {
  position: absolute;
  top: 0%;
  left: 13%;

  display: flex;

  width: 100px;
  height: auto;
  /* margin-left: auto;
   margin-right: auto;
    margin-top: 30px; */
  -webkit-animation: home_rotate__HXvlE linear 30s infinite;
          animation: home_rotate__HXvlE linear 30s infinite;
}

.home_logoHome2__1luXi {
  position: absolute;
  top: 0%;
  left: 78%;

  display: flex;

  width: 100px;
  height: auto;
  /* margin-left: auto;
   margin-right: auto;
    margin-top: 30px; */
  -webkit-animation: home_rotate__HXvlE linear 30s infinite;
          animation: home_rotate__HXvlE linear 30s infinite;
  animation-direction: reverse;
}

.home_twitterCard__237_b {
  width: 350px;
  height: 350px;
  position: absolute;
  z-index: 1;
  top: 65%;
  left: 12%;
}

.home_market1__3Ai3a {
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 12%;
}

.home_body1__1K1X6 {
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 50px;
}

.home_home__z3mBK hr {
  text-align: center;
  height: 2px;
  background-color: #aa8929;
  border-radius: 10px;
  margin-top: 20px;
}

.home_body2__DYwAo {
  position: absolute;
  width: 300px;
  top: 23%;
  left: 55%;
  margin-left: 20px;
}

.home_body3__2_Etd {
  position: absolute;
  width: 400px;
  top: 70%;
  left: 55%;
}

.home_social__1R82b {
  position: absolute;
  width: 400px;
  top: 78%;
  left: 55%;
}

.home_social_icon__2jL7e {
  width: 80px;
}

.home_barre__2ZoE8 {
  margin-top: 650px;
}

.home_news__1iUZ5 {
  margin-top: 80px;
  width: 400px;
  height: 700px;
}

/* Affichage téléphone  */

@media (max-width: 810px) {
  .home_home__z3mBK .home_logoHome1__2a5-J {
    display: none;
  }

  .home_home__z3mBK .home_logoHome2__1luXi {
    display: none;
  }

  .home_twitterCard__237_b {
    display: none;
  }

  .home_home__z3mBK .home_market1__3Ai3a {
    display: none;
  }

  .home_body1__1K1X6 {
    text-align: center;
    display: flex;
  }

  .home_news__1iUZ5 {
    display: none;
  }

  .home_home__z3mBK {
    position: relative;
    padding-left: 5px;
    background: "#313036";
    overflow: hidden;
  }

  .home_home__z3mBK hr {
    display: none;
  }

  .home_body2__DYwAo {
    display: none;
  }

  .home_home__z3mBK .home_body3__2_Etd {
    text-align: center;
    display: flex;
    position: inherit;
  }

  .home_social__1R82b {
    text-align: center;
    display: flex;
    position: inherit;
  }

  .home_titleMobile__3_pzO{
    display : flex;
  }
}


@media (min-width: 810px) {
  .home_titleMobile__3_pzO{
    display: none;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  
  margin-bottom: 30px;
}

.field_label {
  color:aliceblue !important;
}



