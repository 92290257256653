@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes clear {
  100% {
    opacity: 0%;
  }
}

@keyframes sinus {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(200px, -25px);
  }
  50% {
    transform: translate(400px, 25px);
  }
  75% {
    transform: translate(600px, -25px);
  }
  100% {
    transform: translate(800px, 25px);
  }
}

.home {
  position: relative;
  max-width: 100vw;
  height: 1500px;
  background: "#313036";
  overflow: hidden;
}

.home h1 {
  text-align: center;
}

.logoHome1 {
  position: absolute;
  top: 0%;
  left: 13%;

  display: flex;

  width: 100px;
  height: auto;
  /* margin-left: auto;
   margin-right: auto;
    margin-top: 30px; */
  animation: rotate linear 30s infinite;
}

.logoHome2 {
  position: absolute;
  top: 0%;
  left: 78%;

  display: flex;

  width: 100px;
  height: auto;
  /* margin-left: auto;
   margin-right: auto;
    margin-top: 30px; */
  animation: rotate linear 30s infinite;
  animation-direction: reverse;
}

.twitterCard {
  width: 350px;
  height: 350px;
  position: absolute;
  z-index: 1;
  top: 65%;
  left: 12%;
}

.market1 {
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 12%;
}

.body1 {
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 50px;
}

.home hr {
  text-align: center;
  height: 2px;
  background-color: #aa8929;
  border-radius: 10px;
  margin-top: 20px;
}

.body2 {
  position: absolute;
  width: 300px;
  top: 23%;
  left: 55%;
  margin-left: 20px;
}

.body3 {
  position: absolute;
  width: 400px;
  top: 70%;
  left: 55%;
}

.social {
  position: absolute;
  width: 400px;
  top: 78%;
  left: 55%;
}

.social_icon {
  width: 80px;
}

.barre {
  margin-top: 650px;
}

.news {
  margin-top: 80px;
  width: 400px;
  height: 700px;
}

/* Affichage téléphone  */

@media (max-width: 810px) {
  .home .logoHome1 {
    display: none;
  }

  .home .logoHome2 {
    display: none;
  }

  .twitterCard {
    display: none;
  }

  .home .market1 {
    display: none;
  }

  .body1 {
    text-align: center;
    display: flex;
  }

  .news {
    display: none;
  }

  .home {
    position: relative;
    padding-left: 5px;
    background: "#313036";
    overflow: hidden;
  }

  .home hr {
    display: none;
  }

  .body2 {
    display: none;
  }

  .home .body3 {
    text-align: center;
    display: flex;
    position: inherit;
  }

  .social {
    text-align: center;
    display: flex;
    position: inherit;
  }

  .titleMobile{
    display : flex;
  }
}


@media (min-width: 810px) {
  .titleMobile{
    display: none;
  }
}
